.ant-modal-mask {
  z-index: 2000;
}

.ant-modal-wrap {
  z-index: 2000;
}

.preview-modal {
  top: 0;
}

@media screen and (max-width: 767px) {
  .ant-modal-header {
    padding: 8px 12px;
  }

  .ant-modal-close-x {
    width: 39px;
    height: 39px;
    line-height: 39px;
  }

  .ant-modal-body {
    padding: 12px;
  }

  .ant-modal-title {
    font-size: 1.2rem;
  }
}
