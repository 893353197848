/* Variables */
@list-container--vertical-padding: 16px;
@list-container--horizontal-padding: 8px;

/* Classes */
body .list-container {
  margin: 4px;
  padding: calc(@list-container--vertical-padding - 4px)
    @list-container--horizontal-padding;
  width: 100%;
  height: calc(
    100vh - @header-height - @navbar-height - @button-text-vertical-padding * 2 -
      @button-border * 2 - @list-container--vertical-padding * 2
  );
  border: 3px solid transparent;

  &.dropzone__documents-list {
    border: 3px solid @green;
    background-color: rgba(@darker-green, 0.1);
    pointer-events: none;
  }
}

.dropzone {
  border: 3px solid @green !important;
  background-color: rgba(@darker-green, 0.1);
}

body .list-container__col--full-height {
  height: calc(
    100vh - @header-height - @navbar-height - @button-text-vertical-padding * 2 -
      @button-border * 2 - @list-container--vertical-padding * 3.5 - 10px
  ) !important;
}

@media screen and (max-width: 767px) {
  body .list-container__col--full-height {
    height: calc(100vh - 120px) !important;
  }
}

@media screen and (min-width: 992px) {
  body .list-container__col--full-height {
    height: calc(
      100vh - @header-height - @navbar-height - @button-text-vertical-padding *
        2 - @button-border * 2 - @list-container--vertical-padding * 3.5
    ) !important;
  }
}

body .list-container__thumbnail {
  overflow-x: hidden;
  overflow-y: auto;
}

.facturx-active {
  height: 55px !important;
  left: 0;
  position: absolute;
  top: 0;
  width: 100px !important;
  z-index: 900;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
