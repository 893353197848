.virtuoso-item-container {
  padding: 4px;
  display: flex;
  flex: none;
  align-content: stretch;
  box-sizing: border-box;

  .ant-card-bordered .ant-card-cover {
    margin-top: 0;
    margin-right: 0;
    margin-left: 0;
    padding: 5px;
  }
  .ant-card-small > .ant-card-body {
    padding: 5px;
  }
  .ant-card-bordered {
    border: 4px solid @white;
  }
  .ant-card-hoverable:hover {
    box-shadow: none;
    border: 4px solid @primary-color;
  }
}

@media screen and (min-width: 992px) {
  .virtuoso-item-container {
    padding: 1.5rem;
  }
}

.virtuoso-list-container {
  display: flex;
  flex-wrap: wrap;
}
.virtuoso-bordered {
  border: 3px solid rgba(0, 0, 0, 0);
}


.virtuoso{
  height: calc(100vh - @header-height - 80px) !important;
}


@media screen and (max-width: 1360px) {
  .virtuoso{
    height: calc(100vh - @header-height - 124px) !important;
  }

}
