/* Variables */
@dialog-padding: 16px;
@border-radius: 4px;
@border: 0;
@border-size: 3px;

/* CSS classes */
.sidebar-container {
  height: 100%;
  background-color: @white;
  padding-left: 60px;
  width: 300px;
  overflow: auto;

  .ant-btn{
    height: 27px;
    padding: 0 13px
  }
}

.sidebar-dropzone {
  width: 100%;
  border: @border-size solid transparent;

  &.dropzone {
    border: @border-size solid @green;
    background-color: rgba(@darker-green, 0.1);
    pointer-events: none;
  }
}


.sidebar-dropzone {
  border: @border-size solid transparent;

  &.dropzone {
    border: @border-size solid @green;
    background-color: rgba(@darker-green, 0.1);
    pointer-events: none;
  }
}

.sidebar-content {
  width: 100%;
  text-align: center;

  @media screen and (max-width: 1200px) {
    padding: 5px;
  }
  @media screen and (max-height: 768px) {
    padding: 0px 20px;
  }


  .sidebar-item {
    position: relative;
    font-size: 1rem;
  }

  .sidebar-text {
    font-size: xx-large;
    color: @primary-color;
    font-weight: 700;
    @media screen and (max-height: 768px) {
      margin: 6px;
      font-size: x-large;
    }
    @media screen and (max-height: 630px) {
      margin: 0px;
    }
  }

  .sidebar-icon {
    margin: 8px 0;
    padding: 10px;
    border: 6px solid @green;
    border-radius: 10%;
    height: 100px;
    width: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .sidebar-icon__dashed {
    border-style: dashed;
  }

  .sidebar-icon__svg {
    padding: 5px;
    width: 100%;
    fill: @green;
  }

  .sidebar-icon__text {
    font-size: 11px;
    color: @default-grey;
    @media screen and (max-height: 768px) {
      min-width: 75px;
    }
  }

  .sidebar-icon__progressbar-text {
    font-weight: bold;
    padding: 0;
    padding-bottom: 8px;
  }
}

@media screen and (max-width: 767px) {
  .sidebar-icon {
    display: none;
  }

  .ant-upload .ant-btn {
    position: fixed;
    bottom: 10px;
    left: 10px;
    z-index: 1;
  }
}

.scanner-settings {
  .scanner-settings__dialog {
    width: 500px;
    top: 210px;
    text-align: left;
  }

  .p-dialog {
    border: @border;
  }

  .p-dialog .p-dialog-titlebar {
    padding: @dialog-padding;
    color: @default-grey;
    background-color: @white;
    border: @border;
    border-top-left-radius: @border-radius;
    border-top-right-radius: @border-radius;
  }

  .p-dialog .p-dialog-titlebar .p-dialog-title {
    font-weight: 400;
    font-size: 16px;
  }

  .p-dialog .p-dialog-content {
    padding: 0 20px;
    border: @border;
  }

  .p-dialog .p-dialog-footer {
    padding: @dialog-padding;
    border: @border;
    border-bottom-left-radius: @border-radius;
    border-bottom-right-radius: @border-radius;
  }

  .p-dialog .p-dialog-footer .p-button {
    border-radius: 5px;
  }

  .p-dialog .p-dialog-footer .p-button-text {
    padding: 6px 14px;
    font-size: small;
    text-transform: capitalize;
  }

  .dialog__subtittle {
    font-size: 13px;
    color: @default-grey;
  }

  .dialog__subtittle.dialog__subtittle--display {
    display: block;
  }

  .dropdown__title.dropdown__title--display {
    display: block;
  }

  .p-col-dialog {
    padding: 0 0 8px;
    width: 100%;
  }
  .p-col-dialog.p-col-dialog--top {
    padding-top: @dialog-padding;
  }
  .p-col-dialog.p-col-dialog--bottom {
    padding-bottom: @dialog-padding;
  }
}

@media (max-width: 767px) {
  .scanner-settings,
  .sidebar-text {
    display: none;
  }

  .sidebar-content {
    gap: 0 !important;
  }
}

.react-select__menu-portal {
  z-index: 9999 !important;

  .react-select__option.react-select__option--is-focused {
    background-color: lighten(@primary-color, 43%);
  }

  .react-select__option.react-select__option--is-selected {
    background-color: @primary-color;
  }
}

.react-select-container {
  .react-select__control.react-select__control--menu-is-open {
    box-shadow: 0 0 0 1px @primary-color;
  }

  .react-select__control.react-select__control--is-focused {
    box-shadow: 0 0 0 1px @primary-color;
    border-color: @primary-color;
  }
}

#dwtcontrolContainer {
  display: none;
}


.title{
  color: @primary-color;
  font-size: 17px;
  padding-bottom: 4px;
}

.sidebar-section-padding{
  padding: 26px 8px;


  @media only screen and (max-height: 820px) {
    padding: 5px 8px;
  }
}

.section-subtitle{
  font-size: 15px;
  color: @darker-green;
}

.upload-type-container{
  text-align: center;
}


