/* Typography */
h1 {
  margin: 1em 0 0.5em 0;
  border-bottom: 1px solid #d5d5d5;
  padding: 0.1em;
  font-weight: normal;
  font-size: 24px;

  &:first-child {
    margin: 0 0 20px 0;
  }
}

h2 {
  font-size: 20px;
  font-weight: normal;
  margin: 0;
}

a {
  text-decoration: none;
}

hr {
  border: none;
  border-bottom: 1px solid #d5d5d5;
}

hr.separator {
  margin: 16px 0 32px;
  //border-color: transparent;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}
