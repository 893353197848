/* Variables */
@progress-size: 40px;
/* CSS Classes */
.navbar {
  padding: 16px 12px;
  width: 100%;
  background: @dark-green;

  .p-button {
    padding: 0.275rem 1.5rem;
    font-size: 100%;
    position: relative;
    white-space: nowrap;
  }

  .button-book__col .ant-badge-count {
    color: @black;
    font-weight: 600;
  }

  .button-book {
    background-color: transparent;
    border-color: @white;
    color: @white;

    padding: 4px 8px;

    &:enabled:hover,
    &:hover {
      background-color: darken(@green, 7%);
      border-color: @white;
    }

    &:enabled:active,
    &:active {
      background-color: transparent;
      border-color: @white;
      color: @white;
    }
  }

  .button-book--active {
    background-color: @white;
    border-color: @white;
    color: @green;

    &:enabled:hover,
    &:hover {
      background-color: @white;
      border-color: @white;
      color: @green;
    }

    &:enabled:active,
    &:active {
      background-color: @white;
      border-color: @white;
      color: @green;
    }
  }

  .button-send-files {
    background-color: @white;
    border-color: @white;
    color: @green;
    padding: 4px 8px;

    &:enabled:hover,
    &:hover {
      background-color: @white;
      border-color: @white;
      color: @text-secondary-color;
    }
    &:enabled:active,
    &:active {
      background-color: @white;
      border-color: @white;
      color: @green;
    }
  }

  .p-progress-spinner {
    width: @progress-size;
    height: @progress-size;
  }

  .p-progress-spinner-circle {
    stroke-width: 4;
  }

  .badge {
    z-index: 990;
  }
}

@media screen and (min-width: 1200px) {
  .navbar {
    padding: @navbar-height;

    .button-book,
    .button-send-files {
      padding: 4px 15px;
    }
  }
}
