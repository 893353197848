.collapse-notification {
  position: absolute;
  bottom: 10px;
  right: 10px;
  z-index: 2000;
  width: 340px;
  .ant-collapse-header {
    background-color: @green;
    color: @white !important;
    font-weight: bold;
    align-items: center !important;
  }
  .header-button {
    padding: 0;
    color: @white;
    height: 30px;
    font-size: 25px;
  }
  .ant-collapse-content {
    max-height: 300px;
    overflow: auto;
    .ant-collapse-content-box {
      padding: 10px 0;
    }
  }
}
