.company-select .react-select-container {
  .react-select__control.react-select__control--menu-is-open {
    box-shadow: 0 0 0 1px @primary-color;
  }

  .react-select__control.react-select__control--is-focused {
    box-shadow: 0 0 0 1px @primary-color;
    border-color: @primary-color;
  }

  .react-select__option.react-select__option--is-focused {
    background-color: lighten(@primary-color, 43%);
  }

  .react-select__option.react-select__option--is-selected {
    background-color: @primary-color;
  }
}

.accounting-firm-select__button svg {
  fill: @white;
}
