.radio-container{

    .ant-radio + *{
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 190px;
      padding-right: 0;
    }

    .icon{
      border: 1px solid @primary-color;
      border-radius: 10px ;
      height: 18px;
      width: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      align-self: center;
      svg{
        fill: @primary-color;
        width: 4px;
      }
    }


  }

  .divider{
    margin: 1px 0 !important
  }

  .selected-key{
    font-weight: bold;
    color: @primary-color
  }

  .ant-tooltip-content {
    white-space: pre-wrap;

  }
