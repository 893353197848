* {
  box-sizing: border-box;
}

:root {
  font-size: 62.5%;
}

html {
  height: 100%;
}

body {
  font-family: "Roboto", "Helvetica Neue", sans-serif;
  font-size: 1.4rem;
  color: @text-color;
  background-color: @body-bg-color;
  margin: 0;
  padding: 0;
  min-height: 100%;
  -webkit-font-smoothing: antialiased;
  overflow: hidden;
}

img,
svg {
  max-width: 100%;
  vertical-align: middle;
}
button {
  img,
  svg {
    max-width: 100%;
    vertical-align: text-top;
    margin-right: 5px;
  }
}

#dwtcontrolContainer {
  display: none;
}

.ant-layout-header {
  background-color: @primary-color;
  color: @white;
  .ant-btn-link {
    color: @white;
  }
}

.neoactivite-alert {
  width: 350px;
}

.loading-page {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.main-container {
  height: calc(100vh - @header-height);
}

