.card {
  border: 2px solid #e2d9d5  !important;
  border-radius: 4px;
  width: 130px !important;


}


@media screen and (min-width: 992px) {
  .card {
    width: 150px !important;
  }
}

.ant-card-meta-detail {
  width: 100%;
}

.ant-card-cover .ant-image {
  height: 155px;
}

.card .ant-image .ant-image-img {
  height: 100%;
}



.thumbnail-multiple-pages::before,
.thumbnail-multiple-pages::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #fff;
  border-radius: 4px;
  z-index: -1;
}

.thumbnail-multiple-pages::before {
  top: -10px;
  left: 10px;
}

.thumbnail-multiple-pages::after {
  top: -6px;
  left: 6px;
}


.card-selected {
  border: 2px solid @primary-color !important;
}


.badge{
  font-size: 12px
}
